@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("../public/font/Inter-Regular.ttf");
}
@font-face {
  font-family: "Mistral";
  src: url("../public/font/mistral.TTF");
}

.font2 {
  font-family: Mistral;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
