@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "inter";
  src: url("../public/font/Inter-Regular.ttf");
}

.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.swiper-pagination {
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 14px 24px;
  width: auto !important;
  z-index: 0 !important;
}
.achatbtn {
  z-index: 1000000 !important;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: yellow !important;

  backdrop-filter: blur(150px);
}

.swiper-pagination-bullet-active {
  background-color: red !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-qualite {
  background-image: url("../src/components/images/masquequalite.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1050px) {
  .bg-qualite {
    background-image: none;
    /* background-color: white; */
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.gridtabs {
  display: grid;
  grid-column-gap: 16px;
  grid-auto-rows: auto;
  grid-template-columns: min-content 3fr;
}

@media screen and (max-width: 950px) {
  .gridtabs {
    grid-template-columns: min-content 2fr;
  }
  .bg {
    background-color: white !important;
    background-image: none !important;
  }
}

.bg {
  background-image: url("../src/components/images/bg.webp");
  background-size: cover;
}

.rotation {
  border: 4px solid rgb(199, 197, 197);
  background-color: transparent;
  border-top: 3px solid rgba(241, 242, 243,0.2);
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scrolldiv::-webkit-scrollbar {
  height: 5px;
  border-radius: 5px;
}

.bggradient {
  background: linear-gradient(
    to bottom,
    rgba(7, 7, 7, 0.2) 70%,
    rgba(243, 240, 45, 0.8)
  ) !important;
}

.scrolldiv::-webkit-scrollbar-track {
  height: 2px;
  background-color: rgba(226, 224, 224, 0.3);
  border-radius: 5px;
}

.scrolldiv::-webkit-scrollbar-thumb {
  height: 2px;
  background-color: #e4c61bda;
  border-radius: 5px;
}

.scrolldiv::-webkit-scrollbar-thumb:hover {
  background-color: #e4c61bda;
}

.scrolldiv2 {
  /* scroll-snap-type: x mandatory; */
}
@media screen and (min-width: 750px) {
  .scrolldiv2 {
    /* padding-left: 24px; */
    /* padding-right: 124px; */
  }
}

h2,
h1,
h3,
h4,
span {
  margin: 0;
  padding: 0;
}

.scrolldiv2 {
  /* padding-left: 124px; */

  padding-right: 0;
}
.scrolldiv2 > div {
  scroll-snap-align: start;
}

.scrolldiv2::-webkit-scrollbar {
  height: 5px;
  border-radius: 5px;
}

.scrolldiv3::-webkit-scrollbar {
  height: 7px;
  border-radius: 5px;
}
.scrolldiv3::-webkit-scrollbar-track {
  height: 2px;
  background-color: rgb(226, 224, 224);
  border-radius: 5px;
}
.scrolldiv3::-webkit-scrollbar-track {
  height: 2px;
  background-color: rgb(226, 224, 224);
  border-radius: 5px;
}

.scrolldiv3::-webkit-scrollbar-thumb {
  height: 2px;
  background-color: rgba(82, 79, 79, 0.7);
  border-radius: 5px;
}

.scrolldiv2::-webkit-scrollbar-track {
  height: 2px;
  background-color: rgb(226, 224, 224);
  border-radius: 5px;
}

.scrolldiv2::-webkit-scrollbar-thumb {
  height: 2px;
  background-color: rgba(82, 79, 79, 0.7);
  border-radius: 5px;
}

.scrolldiv4::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 5px;
}

.scrolldiv4::-webkit-scrollbar-track {
  background-color: rgb(226, 224, 224);
  border-radius: 5px;
}

.scrolldiv4::-webkit-scrollbar-thumb {
  background-color: rgba(82, 79, 79, 0.7);
  border-radius: 7px;
}
.swiper-slide {
  z-index: 0 !important;
}

.swipe {
  overflow: hidden;
  display: inherit;
}

.fontp {
  font-family: "Khand";
}
.fontp2 {
  font-family: roboto, sans-serif;
}

.paneranim{
  
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation: animepanier 0.3s linear 2;
  perspective: 1000px;
  /* animation-fill-mode: forwards; */
}

@keyframes animepanier {

  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
  
}

body{
  margin: 0;
}
html {
  scroll-behavior: smooth;
}


/*** HERO SECTION CSS CODE ***/
.reservation, .contact{
  margin: 0;
}

header{
  display: flex;
  height: 74px;
  justify-content: space-evenly;
  font-size: 1.1em;
  padding: 0.8em 4em;
  margin-bottom: 0px;
}

header a{
  text-decoration: none;
  color: white;
  /* font-size: 1.1em; */
  font-weight: 400;
  padding: 5px 20px;
  /* font: 14px Inter, sans-serif; */
}

header a:hover{
  color: whitesmoke;
  text-decoration: underline;
  font-weight: 500;
}

header .site-logo{
  margin-right: auto;
  height: 3.7em;
  width: 3.6em;
  margin-top: 0;
 
}

header nav{
  margin-top: .9em;
}

.header--cta{
  margin: .5em 3.7em;
  font-size: 1.11em;
  width: 8.5em;
  height: 1em;
  border-radius: 1.1em;
  padding: .5em 1em;
  border: 2px solid white;  
}

.header--cta:active{
  box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}

.hero{
  background-image: url("images/hero--bg.png");
  background-repeat: no-repeat;
  height: 96vh;
}

.hero--info{
  display: flex;
  justify-content: space-around;
  color: white;
  margin: 0em 4.8em;
}
.hero--text{
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
 .hero--image{
  height: 29.3em;
  width: 23em;
  transform:rotate(0, 1.8, 8.15deg);
  /* margin: 0em 0em; */
  margin-right: 6em;
  margin-bottom: 0em;
}

.hero--cta{
  width: 8em;
  height: 1.12em;
  border-radius: 1em;
  padding: .5em 1em;
  background-color: white;
  color: #EE790D;
  text-decoration: none;
  font-size: 1.11em;
  margin-top: .6em;
  transition: all;
  transition-duration: 2ms;
}
.hero--cta:hover{
    /* text-decoration: underline; */
    font-weight: 500; 
    font-size: 1.13em;
    
}

.hero--cta:active{
  box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}

.hero--title{
  font-size: 3em;
}
.hero--details{
  margin-top: 0em;
}
.sub--title{
  font-family: "Pacifico", cursive;
  font-weight: 300;
  font-style:italic; 
}
.hero--title{
  margin-bottom: .5em;
}

/**** CSS CODE FOR APROPOS SECTION BEGINS HERE ****/
.apropos{
  text-align: center;
  margin-top: 4em;
  margin-bottom: 3em;
  height: 480px;
}
.apropos--item--wrapper{
  display: flex;
  justify-content: space-evenly;
  margin-top: 2em;
  /* margin-bottom: 3em; */
}

.apropos--item--title{
  color: #F93A47;
}

.apropos--title--design1{
  color:#F93A47 ;
  font-family: "Pacifico", cursive;
  font-weight: 700;
  font-style:italic; 
}

.apropos--title--design2{
  color: #F93A47;
}

.apropos--item--image{
  height: 9em;
  width: 7em;
  margin-top: 2em;
}

/*** SERVICE TRAITEUR CSS CODE ***/

.service--traiteur{
  text-align: center;
  margin-top: 3em;
  padding-top: 2em;
  padding-bottom: 2em;
  background-image: url("images/service--traiteur--bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.service--traiteur--title--design1{
  color: #F93A47;
  font-family: "Pacifico", cursive;
  font-weight: 700;
  font-style:italic; 
}
.service--traiteur--title--design2{
  color: #F93A47;
}
.service--traiteur--item--wrapper{
  margin-top: 4em;
  margin-left: 6em;
  margin-right: 6em;

}
.service--traiteur--item--row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}
.service--traiteur--item{
  width: 470px;
  height: 220px;
  background-color: white;
  display: flex;
  padding: 1em 1.5em;
  gap: 2em;
  justify-content: space-between;
  border-radius: 2em;
}

.service--traiteur--item--img{
  height: 15em;
  width: 16em;
  transform:rotate(0, 1.8, 8.15deg);
  transition: all;
  transition-duration: 60ms;
  /* margin-top: .6em;
  margin-bottom: 6em; */
}

.service--traiteur--item--img:hover{
  /* animation: enlarge 800ms; */
  transform: scale(1.2);
}
.service--traiteur--item--title{
  font-family: "Pacifico", cursive;
  font-weight: 300;
  font-style:italic; 
  font-size: 1.5em;
  margin-top: .1em;
}
.service--traiteur--item--units{
  color: #F93A47;
}
.service--traiteur--item--info{
  margin-left: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.service--traiteur--item--text{
  margin-top: 0.4em;
}
.service--traiteur--item--title{
  margin-bottom: 0em;
}
.service--traiteur--item--qty{
  margin-bottom: 0em;
}
.service--traiteur--item--units{
  margin-top: 0.2em;
}
.service--traiteur--item--cta{
  width: 5.5em;
  height: 1.3em;
  padding: .5em .8em;
  color: white;
  text-decoration: none;
  background-color: #F93A47;
  border-radius: .8em;

}

.service--traiteur--item--cta:active{
  box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}



/**** CSS CODE FOR MENU OR GALERY *****/
.galery{
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;
  margin-left: auto;
  margin-right: auto;
}
.galery--title--design1{
  color: #F93A47;
  font-family: "Pacifico", cursive;
  font-weight: 700;
  font-style:italic

}
.galery--title--design2{
  color: #F93A47;

}
.galery-img{
  height: 25em;
  width: 48em;
  margin: 1em auto;
}


/**** CSS CODE FOR RESERVATION *****/
.reservation{
  background-image: url("images/service--traiteur--bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 730px;
  margin-bottom: 0px;
  text-align: center;
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  display: flex;
  justify-content: center;
  gap: 7em;
  
}
/* .reservation--info{
  height: 20em;

} */

.reservation--info--title--design1{
  color: #F93A47;
  line-height: 43.2px;
  font-weight: 700;
  font-family: "Pacifico", cursive;
  font-style:italic;

}
.reservation--info--title--design2{
  color: #F93A47;
  
}
.reservation--info--imgs{
  margin-top: 1em;
  position: relative;
 
}

.reservation--img1{
  width: 275.01px;
  height: 394.99px;
  position: relative;
  top: 1em;
  left: .3em;
  gap: 0px;
  opacity: 0px;
 
}


.reservation--img2{
  width: 275.01px;
  height: 394.4px;
  position: absolute;
  top: .8em;
  left: .6em;
  gap: 0px;
  opacity: 0px;

}
.reservation--form{
  height: 33.5em;
  width: 22em;
  border-radius: 2em;
  margin-top: 1.2em;
  background-color: white;
  padding-block: 2em ;
  padding-inline: 2em;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2.98256px 7.4546px 1px rgba(0, 0, 0, 0.2);
}
.reservation--form > div{
  margin-top: .8em;
  margin-bottom: .8em;
  color: #888888;;
}

.reservation--form > div > input{
  border-radius: .6em;
  width: 22em;
  font-size: .8em;
  line-height: 2px;
  padding: .6em .8em;
  height: 2.5em;
  border: 1px solid #888888;
  cursor: pointer;
}

.reservation--form > div > select{
  border-radius: .6em;
  width: 22em;
  font-size: .8em;
  line-height: 2px;
  padding: .8em 1em;
  height: 3em;
  border: 1px solid #888888;
  cursor: pointer;
}
.reservation--form > div > select, option{
   color: #888888;
}



.reservation--form > div > textarea{
  height: 4.8em;
  width: 22em;
  line-height: 16px;
  border-radius: .6em;
  padding: 1.2em 1em;
  border: 1px solid #888888;
  cursor: pointer;
}

.reservation--form > div > input, select, option, textarea:focus{
  color: #000000;
}
.reservation--form > div > input, select, option, textarea:valid{
  color: #000000;
}
.reservation--form > button{
  height: 2.5em;
  width: 22em;
  margin-left: auto;
  margin-right: auto;
  padding: .5em 1em;
  border-radius: .6em;
  background-color: #E51826;
  border: none;
  color: white;
  margin-top: .2em;
}

.reservation--form > button:active{
  box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}
.reservation--form > div > label{
  font-size: .8em;
  color: black;
  position: absolute;
  transform: translateY(-50%);
  margin-top: -7px;
  background: white;
  padding-left: .1em;
  padding-right: .1em;
  transform: translateX(40%);

}
.toast--valider,
.toast--echec{
  position: absolute;
  left: 4em;
  bottom: 2em;
  /* top: 3em;
  left: .6em; */

}
.reservation--toast{
  position: relative;
  /* display: none; */
}

/**** CSS FOR CONTACT ****/
.contact{
  height: 490px;
}
.contact--row1{
  display: flex;
  gap: 4em;
  background-image: url("images/contact--row1--bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0px;
  height: 68px;
  padding: 2.5em 5em;
  justify-content: space-between;
}
.contact--row1--info{ 
  margin-top: .5em;
  display: flex;
  gap: .6em;
}
.contact--row1--info--text{
  /* font-size: .3em; */
  display: block;
  /* width: 4em; */
}
.contact--row1--info--img{
  height: 50px;
  width: 50px;
  margin-top: .5em;
}

.contact--row1--form{
  display: flex;
  gap: 1.6em;
  margin-top: 1.2em;
}

.contact--row1--form > input{
  width: 18em;
  height: 2.8em;
  border-radius: .6em;
  padding: .2em 1em;
  color: #888888;
  border: 1px solid #000000;
}

.contact--row1--form > input:focus{
  color: black;
  
}
.contact--row1--form > button{
  width: 10em;
  height: 3em;
  color: white;
  background-color: #E51826;
  border-radius: .6em;
  border: none;
  padding: .4em .6em;
}

.contact--row1--form > button:active{
  box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}


.contact--row2{
  display: flex;
  justify-content: space-evenly;
  background-color: #A1131D;
  color: white;
  padding-bottom: 1em;
  padding-top: 1em;
  
}

.contact--row2--col5,
.contact--row2--col2,
.contact--row2--col3,
.contact--row2--col4{
  display: flex;
  flex-direction: column; 
  align-items: center;
  /* text-align: left; */
}


.contact--row2--col1--phone,
.contact--row2--col1--email,
.contact--row2--col1--FAQ{
  display: flex;
  gap: 1em;
  border: 1px solid #FFFFFF;
  padding: 0em .7em;
  margin-bottom: .7em;
  border-radius: .7em;
}

.contact--row2--col1--phone--img,
.contact--row2--col1--email--img,
.contact--row2--col1--FAQ--img{
  height: 1.2em;
  width: 1.3em;
  margin-top: 1em;
}
.contact--row2--col1--phone--img{
  margin-top: 1.7em;
}


.contact--row2--col1--title,
.contact--row2--col2--title,
.contact--row2--col3--title,
.contact--row2--col4--title,
.contact--row2--col5--title{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: .8em;
  margin-top: .5em;
}
/* .contact--row2--col2--title{
  margin-right: 4em;
} */

.contact--row2--col2--list,
.contact--row2--col3--list,
.contact--row2--col4--list{
   list-style: none;
   margin-top: .1em;
   display: flex;
   flex-direction: column;
   gap: .1em;
   align-items: normal;
}

.contact--row2--col2--list > li:hover,
.contact--row2--col3--list > li:hover,
.contact--row2--col4--list > li:hover{
 text-decoration: underline;
}

.contact--row2--col5--img{
  display: flex;
  gap: .6em;
}
.col5--img1,
.col5--img2,
.col5--img3{
   height: 1.3em;
   width: 1.3em;
}

.contact--row3{
  display: flex;
  background-color: #FFFFFF;
  height: 50px;
  justify-content: space-evenly;
  gap: 4em;
}
.contact--row3--img{
  height: 2.4em;
  width: 2.4em;
  margin-top: .5em;
  
}

.hamburger {
  display: none;
}

/* ** Responsive for Hero section begins here ****/


  @media (max-width: 1246px){
    .service--traiteur--item{
      max-width: 350px;
      gap: 1em;
    }
    .service--traiteur--item--row{
      margin-left: auto;
    }
    .service--traiteur--item--wrapper{
      margin-left: 5em;
      margin-right: 5em;
    }
    .service--traiteur--item--qty{
      margin-top: .1em;
      font-size: .9em;
    }
    .service--traiteur--item--title{
      font-size: 1.3em;
      font-weight: 600;
    }
    .service--traiteur--item--text{
      font-size: .9em;

    }
    .service--traiteur--item--units{
      margin-top: .1em;
      font-size: 1em;
      margin-bottom: 0px;
    }
    .service--traiteur--item--cta{
      /* transform: scale(.9); */
      width: 5.5em;
      height: 1.3em;
      margin-top: .4em;
    }
    .service--traiteur--item--img{
      transform: scale(.8);
    }
    .service--traiteur--item--img:hover{
      transform: scale(1.2);
    }

      
      .contact--row1{
        height: 3em;
        /* padding: 2.5em 5em; */
        padding-left: 3em;
        padding-right: 3em;
        gap: 2em;
      }
      .contact--row1--info{ 
        margin-top: .5em;
        display: flex;
        gap: .6em;
      }
      .contact--row1--info--text{
        font-size: 1em;
        display: flex;
        width: 16em;
        height: 5em;
        margin-top: .4em;
      }
      .contact--row1--info--img{
        height: 42px;
        width: 42px;
        margin-top: .5em;
      }
      
      .contact--row1--form{
        display: flex;
        gap: 1em;
        margin-top: 1.2em;
      }
      
      .contact--row1--form > input{
        /* transform: scale(.8); */
        max-width: 12em;
      }
      .contact--row1--form > button{
      /* transform: scale(.8); */
      max-width: 7em;
      }

  }

  @media (max-width: 1138px) {
    header a{
      text-decoration: none;
      color: white;
      font-weight: 400;
      padding: 3px 15px;
    }
    header nav{
      margin-left: .6em;
    }
    .header--cta{
      margin-left: .1em;
      width: 4em;
      font-size: 1em;
    }
    .hero--title{
      font-size: 2.5em;
    }
    .hero--image{
      max-height: 23em;
      max-width: 29em;
      transform:rotate(0, 1.8, 8.15deg);
      margin-top: 0em;
    }
    .hero{
      height: 86vh;
    } 
  }

  @media (max-width: 1003px){
    header{
      font-size: 1em;
      padding: 0.8em 2em;
      margin-bottom: 0px;
      max-width: 100%;
      flex-wrap: wrap;
      margin-right: .001em;
    }
    .hero--image{
      display: none;
    }
    .site-logo{
      margin-left: 3em;
    }

    header a{
      text-decoration: none;
      color: white;
      font-weight: 400;
      padding: .3em .7em;
    }
    .header--cta{
      margin-left: .1em;
      width: 3em;
      white-space: initial;
    }
    
  }
 
 
  @media (max-width: 600px) {
    .hero-navigation {
      max-width: 100%;
      flex-wrap: wrap;
    }
    .hero-nav {
      max-width: 100%;
      flex-direction: row;
    }
    .hero--cta{
      width: 90%;
      text-align: center;
      margin-top: 1em;
    }
  }
  
  .hamburger {
    display: none;
  }

  .home-link {
    flex-grow: 1;
    white-space: nowrap;
  }
  .header--cta{
    flex-grow: 1;
    white-space: nowrap;

  }
   
  @media (max-width: 600px) {
    .home-link {
      white-space: initial;
    }
    .header--cta {
      white-space: initial;
    }
  }

  
  @media screen and (max-width: 870px) {
    .hero-navigation {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .hero-nav {
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .site-logo2{
    margin-left: 2em;
    height: 3.7em;
    width: 3.6em;
    margin-top: 0;
    margin-bottom: 1em;

  }
  .hambericon {
    height: 34px;
    width: 34px;
    cursor: pointer;
    margin-top: -25px;
    color: #ffffff;
  }
  
  .menuhamber {
    display: none;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    transform: translateX(-110%);
    z-index: 999 !important;
    transition: all ease 0.5s;
    height: 100vh !important;
    min-height: 100vh !important;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: white;
    color: black;
  }
  
  .listlinkhamburger {
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  
  .listlinkhamburger li {
    font-size: 1.5rem;
    font-weight: 500;
    color: #EE790D;
    cursor: pointer;
  }
  
  .listlinkhamburger li a {
    text-decoration: none;
    color: inherit;
  }
  
  @media only screen and (max-width: 872px) {
    .hero-nav {
      display: none !important;
    }
    .hamburger {
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 4%;
      height: 100%;
    }
    .hero-navigation {
      flex-direction: row !important;
      justify-content: center;
      align-items: center;
    }
  
    .menuhamber {
      position: fixed;
      display: flex;
      padding-top: 14px;
      justify-content: flex-start;
      align-items: flex-start;
    }
  
    .menuhamber .img {
      padding-left: 24px !important;
    }
  }
  .iconhamberclose {
    height: 34px;
    width: 34px;
    color: #EE790D;
    padding-right: 1em;
  }
  .closesection {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;
  }
  .centerclose {
    height: auto;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
  }

  @media (max-width: 918px){
    .service--traiteur--item--wrapper{
      margin-left: 4em;
    }
    .service--traiteur--item--row{
      max-width: 100%;
    }
    .service--traiteur--item{
      max-width: 300px; 
    }
    .service--traiteur--item--img:hover{
      transform: scale(1) 
    }
    .contact--row1{
      gap: .1em;
    }
    .contact--row1--form{
      gap: .5em;
      margin-top: .5em;

    }
  


  }
  
  @media (max-width: 862px) {
    .contact--row1{
      display: flex;
      flex-direction: column;
      padding-top: .8em;
      margin-bottom: 0em;
      align-items: flex-start;
      background-size: cover;
      height: 150px;
      padding-left: 8em;
    }
    .contact--row1--info{
      max-width: 100%;
      margin-top: 0em;
      margin-bottom: 0em;
      gap: 2em;
     }
     .contact--row1--info--img{
      height: 50px;
      width: 50px;
      align-items: flex-start;
      
    }
     .contact--row1--form{
      max-width: 100%;
      margin-top: 0em;
      margin-bottom: 4em;
     }
     .contact--row1--form > input{
      max-width: 14em;
     
    }
    .contact--row1--form > button{
      max-width: 10em;
      height: 3em;
      padding: .1em .2em;
      
    }
     .contact--row3{
      gap: 2em;
    }
    .reservation{
      gap: 2em;
    }
    .service--traiteur--item--wrapper{
      margin-left: 2.6em;
    }
  
    .galery-img{
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 20em;
    }
  }
  
  @media (max-width: 796px) {
   
    .reservation{
      display: flex;
      flex-direction: column;
      margin-top: 1em;
      height: 1200px;
      align-items: center;
      gap: .1em;
      padding-bottom: 4em;
      padding-top: 2em;
    }
    .reservation--info{
      margin-top: 0em;
    }
    .contact--row1--form > button{
      height: 3.4em;
      /* padding: .1em .2em;  */  
    }
    .contact--row1{
      padding-left: 3em;
     
    }
    .service--traiteur--item--row{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2em;
    }
    .service--traiteur--item{
      max-width: 88%;
      gap: .01em;
    }

    .service--traiteur--item--img:hover{
      transform: scale(.9);
    }
    .contact--row2{
      gap: .1em;
      text-wrap: wrap;
      padding-left: .1em;
    }
    .apropos{
      text-wrap: wrap;
    }
    .contact--row3{
      text-wrap: wrap;
      padding-left: .2em;
    }
  }
  @media (max-width: 600px){
    .apropos{
      height:1300px;
      gap: 1em;
    }
    .apropos--item--wrapper{
      display: flex;
      flex-direction: column;
      gap: .1em;
      align-items: center;
    }
    .apropos--item{
      margin-bottom: 0em;
      margin-top: 0em;
      max-width: 80%;
    }
    .apropos--item--title{
      margin-top: .1em;
      margin-bottom: 0em;
    }
    .apropos--item--text{
      margin-top: .3em;
    }
    .apropos--item--image{
      margin-top: 1.5em;
    }
    .service--traiteur--item--row{
      margin-left: 1em;
      margin-right: .2em;
    }
    .service--traiteur--item{
      max-width: 90%;
    }
    .contact--row2{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 100%; 
    }
    .contact--row2--col1,
    .contact--row2--col5{
      margin-left: 2em;
      max-width: 80%;
    }
    .contact--row1--info{
      margin-bottom: 0em;
      gap: .4em;
      margin-top: 1em;
    }
    .contact--row1--form{
      display: flex;
      flex-direction: column;
      margin-top: 0%;
      width: 100%;
    }
    .contact--row1{
      display: flex;
      flex-direction: column;
      height: 250px;
      gap: .1em;
      padding-right: 0.1em;
      max-width: 100%;
    }
    .contact--row1--form > input{
     max-width: 100%;
      
    }
    .contact--row1--form > button{
      width: 300px;
       
     }

  }

  
  
  @media (max-width: 475px) {
    .reservation,
    .galery,
    .service--traiteur,
    .apropos{
      text-wrap: wrap;
    }
    .reservation--form{
      max-width: 16em;
    }
    .reservation--form > div > input, select, textarea{
      max-width: 16em;
    }
    .reservation--form > button{
      max-width: 16em;
    }
    .reservation--form > select{
      background-color: white;
    }
    .reservation--form > div > textarea{
      line-height: 16px;
      
    }
    .service--traiteur--item{
      max-width: 16em;
      margin-left: 1.2em;
    }
    .service--traiteur--item--img{
      max-width:  10em;
      /* max-width: 12em; */
      margin-right: 1.5em;
    }
    .contact--row1--info{
      text-wrap: wrap;
    }
    .contact--row1--info--text{
      text-wrap: wrap;
    }
    .hero{
      height: 70vh;
    }
    .hero--info{
      margin-left: 2em;
    }
    .site-logo{
      margin-left: .1em;
    }
    .hero--title{
      margin-top: .2em;
    }
    .galery-img{
      height: 17em;
    }
    
    .toast--valider,
    .toast--echec{
      max-width: 16em;
      max-height: 50px;
      margin-left:  auto;
      margin-right: auto;
      margin-top: .6em;
      position: absolute;
      bottom: 1em;
      box-shadow: 0px 2.98256px 7.4546px rgba(0, 0, 0, 0.5);
    }
  
  }








 









